import { Box, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DisplaySVGImage from "../../../GlobalComponents/DisplaySVGImage";
import GrayHeading4 from "../../../GlobalComponents/GlobalTexts/GrayHeading4";
import LightGrayHeading4 from "../../../GlobalComponents/GlobalTexts/LightGrayHeading4";
import RadioGroupComponent from "../../../GlobalComponents/RadioGroupComponent";
import SelectComponent from "../../../GlobalComponents/SelectComponent";
import {
  apiFailed,
  apiSuccess,
  editSyncedAudience,
  handleFailureModal,
  handleSuccessModal,
  updateFrequencyAudienceReset,
} from "../../../actions/actions";
import Icons from "../../icons";

const radioButtonsList = [
  {
    id: 1,
    text: "Push Only Once",
    value: "push",
  },

  {
    id: 2,
    text: "Update List",
    value: "update",
  },

  {
    id: 3,
    text: "Replace List",
    value: "replace",
  },
];

const radioButtonsList2 = [
  {
    id: 1,
    text: "Push Only Once",
    value: "push",
  },
  {
    id: 3,
    text: "Replace List",
    value: "replace",
  },
];

export const radioLabelStyleProps = {
  color: "#A4A4A4",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "16.8px",
};

const periodList = [
  {
    id: 1,
    label: 1,
    value: 1,
  },

  {
    id: 2,
    label: 2,
    value: 2,
  },
  {
    id: 3,
    label: 3,
    value: 3,
  },
  {
    id: 4,
    label: 4,
    value: 4,
  },

  {
    id: 5,
    label: 5,
    value: 5,
  },

  {
    id: 6,
    label: 6,
    value: 6,
  },
  {
    id: 7,
    label: 7,
    value: 7,
  },
  {
    id: 8,
    label: 8,
    value: 8,
  },

  {
    id: 9,
    label: 9,
    value: 9,
  },

  {
    id: 10,
    label: 10,
    value: 10,
  },
  {
    id: 11,
    label: 11,
    value: 11,
  },
  {
    id: 12,
    label: 12,
    value: 12,
  },
];

const popUpMessageObj = {
  replace: "Replace",
  update: "Update",
  push: "Push",
};

const defaultPushedType = "push";

const SAUpdateFrequencyEdit = ({ closeDraw }) => {
  const dispatch = useDispatch();

  const selectedSynchedAudienceData = useSelector(
    (state) => state?.fetch_custom_filter_reducer?.selectedSynchedAudienceData
  );

  const editSyncedAudienceResponse = useSelector(
    (state) => state?.fetch_custom_filter_reducer?.editSyncedAudienceResponse
  );

  const [pushAudienceType, setPushAudienceType] = useState(
    selectedSynchedAudienceData?.operation_type
  );

  const [updateReplacePeriod, setUpdateReplacePeriod] = useState(
    selectedSynchedAudienceData?.frequency_months
  );

  const [showErrorMsg, setShowErrorMsg] = useState(null);

  const handleClose = () => {
    closeDraw(false);
  };

  const handleRadioChange = (event) => {
    setPushAudienceType(event.target.value);
  };

  const handleSelectChange = (event) => {
    setUpdateReplacePeriod(event.target.value);
    setShowErrorMsg(null);
  };

  function displayErrorText() {
    return (
      <>
        <LightGrayHeading4 color="red" fontSize="14px" lineHeight="26.4px">
          Please select the frequency*
        </LightGrayHeading4>
      </>
    );
  }

  const handleSave = () => {
    let finalObj = {
      audience_id: selectedSynchedAudienceData?._id,
      source: selectedSynchedAudienceData?.source,
      update_replace: pushAudienceType,
    };

    if (pushAudienceType !== defaultPushedType) {
      if (updateReplacePeriod === null) {
        setShowErrorMsg(true);
      } else {
        finalObj["update_replace_period"] = updateReplacePeriod;

        dispatch(editSyncedAudience(finalObj));
      }
    } else {
      dispatch(editSyncedAudience(finalObj));
    }
  };

  useEffect(() => {
    if (editSyncedAudienceResponse?.responseMessage === apiSuccess) {
      dispatch(
        handleSuccessModal({
          value: true,
          message1: `${popUpMessageObj[pushAudienceType]} Successful`,
        })
      );
      dispatch(updateFrequencyAudienceReset());
      handleClose();
    } else if (editSyncedAudienceResponse?.responseMessage === apiFailed) {
      dispatch(
        handleFailureModal({
          value: true,
          message1: `${popUpMessageObj[pushAudienceType]} Failed`,
        })
      );
      dispatch(updateFrequencyAudienceReset());
    }
  }, [editSyncedAudienceResponse]);

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          padding: "1.5rem 2rem",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              display: "flex",
              gap: 5,
              alignItems: "center",
            }}
          >
            <DisplaySVGImage
              src={Icons?.reMarketIconBlackImage}
              alt={"remarket-icon-black"}
              height={"30px"}
              width={"30px"}
            />
            <LightGrayHeading4
              color="#4E4E4E"
              fontSize="22px"
              lineHeight="26.4px"
            >
              Edit - Auto Sync
            </LightGrayHeading4>
          </Box>

          <Box style={{ cursor: "pointer" }} onClick={handleClose}>
            <DisplaySVGImage
              src={Icons?.closeIconRedImage}
              alt={"close-icon-red"}
              height={"30px"}
              width={"30px"}
            />
          </Box>
        </Box>

        <Divider />

        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <GrayHeading4>Selected Audience:</GrayHeading4>

          <LightGrayHeading4>
            {selectedSynchedAudienceData?.audience_name || null}
          </LightGrayHeading4>
        </Box>

        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <GrayHeading4>
            How do you like to push this audience in the future?
          </GrayHeading4>

          <RadioGroupComponent
            radioButtonsList={
              selectedSynchedAudienceData?.source === "shopify"
                ? radioButtonsList2
                : radioButtonsList
            }
            handleChange={handleRadioChange}
            value={pushAudienceType}
            radioLabelStyleProps={radioLabelStyleProps}
          />
        </Box>

        {pushAudienceType !== defaultPushedType ? (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <GrayHeading4>Select Frequency :</GrayHeading4>
            <Box>
              <SelectComponent
                optionsList={periodList}
                onChange={handleSelectChange}
                value={updateReplacePeriod}
                changeLabelText={
                  updateReplacePeriod === null
                    ? "Select Frequency"
                    : updateReplacePeriod === 1
                    ? `Every Month`
                    : `Every ${updateReplacePeriod} Months`
                }
                changeBorderColor="#DAE4ED"
              />

              {showErrorMsg ? displayErrorText() : null}
            </Box>
          </Box>
        ) : null}

        <Box
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            border: "1px solid #FAFAFA",
            backgroundColor: "#FAFAFA",
            width: "95.8%",
            padding: "1.1rem 1.3rem",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Box
            style={{
              fontSize: "1rem",
              fontWeight: 700,
              borderRadius: 0,
              border: "1px solid #F58120",
              backgroundColor: "#F58120",
              padding: "0.65rem 2rem",
              cursor: "pointer",
            }}
            onClick={handleSave}
          >
            <LightGrayHeading4 color="#ffffff">Save Settings</LightGrayHeading4>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SAUpdateFrequencyEdit;
