import React, { useEffect } from "react";
import "./App.css";
import { HashRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import thunkMiddleware from "redux-thunk";
import { applyMiddleware, createStore } from "redux";
import { createHashHistory } from "history";
import axios from "axios";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import rootReducer from "./reducers/index";
import DispatchActions from "./components/dispatchActions";
import User from "./components/user";
import { logoutUser } from "./actions/actions";
import CookiePolicy, { getCookie } from "./components/cookiePoilcy";
import { withSuspense } from "./pages/withSuspense";
const HomePage = React.lazy(() => import("./pages/homePage"));
const ShopifyHomePage = React.lazy(() => import("./pages/shopifyHomePage"));
const GroupPage = React.lazy(() => import("./pages/groupPage"));
const NewCustomerRemarketPage = React.lazy(() =>
  import("./pages/newCustomerRemarketPage")
);
const NewDuplicateRemarketPage = React.lazy(() =>
  import("./pages/duplicateRemarketPage")
);
const HelpCenter = React.lazy(() => import("./pages/helpCenterPage"));
const HelpCenterListOfFAQ = React.lazy(() =>
  import("./components/helpCenter/helpCenterListOfFAQ")
);
const HelpCenterQA = React.lazy(() =>
  import("./components/helpCenter/helpCenterQA")
);
const searchListFAQ = React.lazy(() =>
  import("./components/helpCenter/searchListFAQ")
);
const ContactUs = React.lazy(() => import("./components/forms/contactUs"));
const CustomerTrend = React.lazy(() => import("./pages/customerTrend"));
const CookiePolicyPage = React.lazy(() => import("./pages/cookiePolicyPage"));
const ResetPasswordPage = React.lazy(() => import("./pages/resetPasswordPage"));
const ForgotPasswordPage = React.lazy(() =>
  import("./pages/forgotPasswordPage")
);
const ReviewDataStatus = React.lazy(() =>
  import("./components/signup/reviewDataStatus")
);
const AddDetailsPage = React.lazy(() => import("./pages/addDetailsPage"));
const TrackingScript = React.lazy(() =>
  import("./components/signup/trackingScript")
);
const EmailActivationResponse = React.lazy(() =>
  import("./components/signup/emailActivationResponse")
);
const EmailActivationPage = React.lazy(() =>
  import("./components/signup/emailActivation")
);
const SignUpPage = React.lazy(() => import("./pages/signupPage"));
const PredictionPage = React.lazy(() => import("./pages/predictionPage"));
const TermsConditionsPage = React.lazy(() =>
  import("./pages/termsConditionsPage")
);
const PrivacyPolicyPage = React.lazy(() => import("./pages/privacyPolicyPage"));
const ConnectionsPage = React.lazy(() => import("./pages/connectionsPage"));
const NotFoundPage = React.lazy(() => import("./pages/notFoundPage"));
const LogoutPage = React.lazy(() => import("./pages/logoutPage"));
const SettingsPage = React.lazy(() => import("./pages/settingsPage"));
const AudienceAnalyticsPage = React.lazy(() =>
  import("./pages/audienceAnalyticsPage")
);
const IntegrationsPage = React.lazy(() => import("./pages/integrationsPage"));
const DoNotSellPage = React.lazy(() => import("./pages/doNotSell"));
const CustomerAnalytics = React.lazy(() =>
  import("./pages/customerAnalyticsPage")
);
const ProductAnalytics = React.lazy(() => import("./pages/productAnalytics"));
const MFAVerification = React.lazy(() => import("./pages/mfaVerification"));
const ExperimentPage = React.lazy(() => import("./pages/experimentPage"));
const ExperimentsSummary = React.lazy(() =>
  import("./components/experiments/experimentsSummary")
);
const GDPRAndCCPA = React.lazy(() => import("./pages/gdprAndccpa"));
const ExperimentResult = React.lazy(() =>
  import("./pages/experimentResultPage")
);
const AudienceManagerPage = React.lazy(() =>
  import("./pages/audienceManagerPage")
);

const SynchedAudiencesDetailedViewPage = React.lazy(() =>
  import(
    "./components/audienceManager/synchedAudiences/synchedAudiencesDetailedView"
  )
);
const SelectedAudience = React.lazy(() => import('./components/audienceManager/audiences/selectedAudience'))

export const history = createHashHistory();

export function reloadPage() {
  window.location.reload(true);
}

export const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));
axios.defaults.baseURL = process.env.REACT_APP_AppUrl;
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status || 403 === error.response.status) {
      store.dispatch(logoutUser());
      history.replace("/");
      reloadPage();
    } else {
      return Promise.reject(error);
    }
  }
);
const font = '"Lato", sans-serif';

export const theme = createTheme({
  palette: {
    common: {
      white: "#fff",
      black: "#000",
      whiteGrey: "#1d314e",
      cyan: "#00A3FF",
      success: "#00A850",
      error: "#ED4A4A",
      blue: "#2D9CDB",
      green: "#00D83A",
    },
    grey: {
      coolGrey: "#F2F6F9",
      grey1: "#4E4E4E",
      grey2: "#747474",
      grey3: "#f2f6f9",
      lightGrey: "#A4A4A4",
    },
    nx: {
      green: "#00D83A",
    },
    /*grey:{
      grey1:"#F2F6F9"
    },*/
    primary: {
      light: "#1098F7",
      main: "#1E334E",
      dark: "#A2B6C4",
      contrastText: "#CF0505",
    },
    secondary: {
      light: "#f58120",
      main: "#FB4E4E",
      dark: "#222222",
      contrastText: "#293d57",
    },
    backgroundColors: {
      overview: "#f9fafb",
      basket: "#4AA459",
      yellow: "#FFEAA7",
    },
    hoverColors: {
      orange: "#E16904",
    },
    white: {
      mainBg: "#e5e5e5",
    },
    text: {
      primary: "#1E334E",
      secondary: "#F58120",
      disabled: "#CF0505",
      hint: "#FB4E4E",
    },
    segment: {
      high: "rgb(245, 129, 32)",
      mid: "rgb(0, 163, 255)",
      low: "rgb(124, 124, 130)",
    },
  },
  typography: {
    fontSize: "1rem",
    fontFamily: font,
    h1: {
      fontSize: "2rem",
    },
    h2: {
      fontSize: "1.5rem",
    },
    h3: {
      fontSize: "1.25rem",
    },
    h4: {
      fontSize: "1.125rem",
    },
    h5: {
      fontSize: "0.875rem",
    },
    body1: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "0.75rem",
    },
  },
  status: {
    danger: "orange",
  },
  overrides: {
    MuiChip: {
      deleteIcon: {
        height: 21,
        color: "black",
      },
      label: {
        padding: "5px 12px",
      },
    },
  },
});

function App() {
  useEffect(() => {
    let visited = getCookie("lxrin_ck");
    if (visited === "mandatory") {
      window[`ga-disable-${process.env.REACT_APP_GAId}`] = true;
    }
  }, []);
  return (
    <Provider store={store}>
      <DispatchActions />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <HashRouter history={history}>
            <Switch>
              <Route exact path="/" component={User} />
              <Route exact path="/home" component={withSuspense(HomePage)} />
              <Route
                exact
                path="/customer-analytics/:selectedTab"
                component={withSuspense(CustomerAnalytics)}
              />
                <Route
                exact
                path="/customer-analytics/:selectedTab/re-authenticated"
                component={withSuspense(CustomerAnalytics)}
              />
                <Route exact path="/shopify-home" component={withSuspense(ShopifyHomePage)}/>
                <Route exact path={'/groups/:segment'} component={withSuspense(GroupPage)}/>
                <Route exact path={'/duplicate'} component={withSuspense(NewCustomerRemarketPage)}/>
                <Route exact path={'/customers1'} component={withSuspense(NewDuplicateRemarketPage)}/>
                <Route exact path={'/customers/:selectedTab'} component={withSuspense(AudienceManagerPage)}/>
                <Route exact path={'/customers/:selectedTab/re-authenticated'} component={withSuspense(AudienceManagerPage)}/>

                <Route exact path = {`/customers/synced-audiences/:id`} component={withSuspense(SynchedAudiencesDetailedViewPage)}/>
                <Route exact path = {`/customers/audiences/:id`} component={withSuspense(SelectedAudience)}/>
                <Route exact path = {`/customers/audiences/:id/re-authenticated`} component={withSuspense(SelectedAudience)}/>
                <Route exact path={'/settings/:param'} component={withSuspense(SettingsPage)}/>
                <Route exact path={'/settings/:param/:source/:page'} component={withSuspense(SettingsPage)}/>
                <Route exact path={'/connections'} component={withSuspense(ConnectionsPage)}/>
                <Route exact path={'/audienceAnalytics'} component={withSuspense(AudienceAnalyticsPage)}/>
                <Route exact path={'/logout'} component={withSuspense(LogoutPage)}/>
                <Route exact path={'/privacy-policy'} component={withSuspense(PrivacyPolicyPage)}/>
                <Route exact path={'/terms'} component={withSuspense(TermsConditionsPage)}/>
                <Route exact path={'/predictions'} component={withSuspense(PredictionPage)}/>
                <Route exact path={'/signup'} component={withSuspense(SignUpPage)}/>
                <Route exact path={'/add_details'} component={withSuspense(AddDetailsPage)}/>
                <Route exact path={'/email_activation'} component={withSuspense(EmailActivationPage)}/>
                <Route exact path={'/email_activation_response'} component={withSuspense(EmailActivationResponse)}/>
                <Route exact path={'/tracking_script'} component={withSuspense(TrackingScript)}/>
                <Route exact path={'/review_data_status'} component={withSuspense(ReviewDataStatus)}/>
                <Route exact path={'/forgot_password'} component={withSuspense(ForgotPasswordPage)}/>
                <Route exact path={'/password_reset_response'} component={withSuspense(ResetPasswordPage)}/>
                <Route exact path={'/contact_us'} component={withSuspense(ContactUs)}/>
                <Route exact path={'/help_center/categories'} component={withSuspense(HelpCenter)}/>
                <Route exact path={'/help_center/:search'} component={withSuspense(searchListFAQ)}/>
                <Route exact path={'/help_center/categories/:param'} component={withSuspense(HelpCenterListOfFAQ)}/>
                <Route exact path={'/help_center/categories/:param/:qaid/:question'} component={withSuspense(HelpCenterQA)}/>
                <Route exact path={'/customer-trend'} component={withSuspense(CustomerTrend)}/>
                <Route exact path={'/cookie_policy'} component={withSuspense(CookiePolicyPage)}/>
                <Route exact path={'/do-not-sell'} component={withSuspense(DoNotSellPage)}/>
              {/* <Route exact path={'/integrate_shopify'} component={withSuspense(AddShopify)}/> */}
              {/* <Route exact path={'/integrate_bigcom'} component={withSuspense(AddBigcom)}/> */}
              <Route
                exact
                path={"/integrations"}
                component={withSuspense(IntegrationsPage)}
              />
              <Route
                exact
                path={"/product_analytics"}
                component={withSuspense(ProductAnalytics)}
              />
              <Route
                exact
                path={"/mfa_verification"}
                component={withSuspense(MFAVerification)}
              />
              <Route
                exact
                path={"/gdpr-ccpa-compliance"}
                component={withSuspense(GDPRAndCCPA)}
              />
              <Route
                exact
                path={"/experiment/:id?"}
                component={withSuspense(ExperimentPage)}
              />
              <Route
                exact
                path={"/experiment-summary"}
                component={withSuspense(ExperimentsSummary)}
              />
              <Route
                exact
                path={"/experiment-result/:id?"}
                component={withSuspense(ExperimentResult)}
              />
              <Route component={withSuspense(NotFoundPage)} />
            </Switch>
            <CookiePolicy />
          </HashRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
}

export default App;
