import { CircularProgress, Grid, useTheme } from "@mui/material";
import * as _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { connect, useDispatch, useSelector } from "react-redux";
import { history, reloadPage } from "../App";
import {
  PopulationData,
  SegmentOrderData,
  SegmentPathData,
  SegmentProductData,
  a_initial_dates,
  all_initial_dates,
  b_initial_dates,
  business_profile,
  c_initial_dates,
  clearPushAudienceFBStatus,
  clearPushAudienceStatus,
  clearUpdateReplaceAudienceFBStatus,
  clearUpdateReplaceAudienceStatus,
  fetch_custom_filters,
  overview_compare,
  overview_data,
  retry_re_market_action,
  segment_page_tab,
  selectedAudienceDetails,
  set_window_height,
} from "../actions/actions";
import GroupPageStyles from "../assets/jss/groupPageStyles";
import DowntimeBanner from "../components/downtimeBanner";
import Footer from "../components/footer";
import CustomerGroup from "../components/getGroupDetails";
import CommonParamPage from "../components/groupPage/commonParamPage";
import ConversionPath from "../components/groupPage/conversionPath";
import GroupInsights from "../components/groupPage/groupInsights";
import GroupOverview from "../components/groupPage/groupOverview";
import MonthTrendHeader from "../components/groupPage/monthTrendHeader";
import ProductPage from "../components/groupPage/productPage";
import RemarketDialog from "../components/remarketDialog/remarketDialog";
import { useParams } from "react-router-dom";

function getPageContent(
  pageSegment,
  classes,
  tabValue,
  segment_path_reducer,
  segment_product_reducer,
  segment_order_reducer,
  segment_compare_order_reducer,
  segment_compare_product_reducer,
  segment_compare_path_reducer,
  start,
  end,
  compareStart,
  compareEnd,
  clustered_period,
  groupData,
  hasLXRTracking,
  trackingReady,
  dataSourceLength
) {
  switch (tabValue) {
    case "customers":
      return (
        <CommonParamPage
          selectedSeg={pageSegment}
          classes={classes}
          property={tabValue}
          orderData={segment_order_reducer}
          compareData={segment_compare_order_reducer}
          productData={segment_product_reducer}
          compareProductData={segment_compare_product_reducer}
          durationFrom={start}
          durationTo={end}
          compareFrom={compareStart}
          compareTo={compareEnd}
          hasLXRTracking={hasLXRTracking}
          trackingReady={trackingReady}
          dataSourceLength={dataSourceLength}
        />
      );
    case "orders":
      return (
        <CommonParamPage
          selectedSeg={pageSegment}
          classes={classes}
          property={tabValue}
          orderData={segment_order_reducer}
          compareData={segment_compare_order_reducer}
          productData={segment_product_reducer}
          compareProductData={segment_compare_product_reducer}
          durationFrom={start}
          durationTo={end}
          compareFrom={compareStart}
          compareTo={compareEnd}
          hasLXRTracking={hasLXRTracking}
          trackingReady={trackingReady}
          dataSourceLength={dataSourceLength}
        />
      );
    case "revenue":
      return (
        <CommonParamPage
          selectedSeg={pageSegment}
          classes={classes}
          property={tabValue}
          orderData={segment_order_reducer}
          compareData={segment_compare_order_reducer}
          productData={segment_product_reducer}
          compareProductData={segment_compare_product_reducer}
          durationFrom={start}
          durationTo={end}
          compareFrom={compareStart}
          compareTo={compareEnd}
          hasLXRTracking={hasLXRTracking}
          trackingReady={trackingReady}
          dataSourceLength={dataSourceLength}
        />
      );
    case "convPaths":
      return (
        <ConversionPath
          data={segment_path_reducer}
          compareData={segment_compare_path_reducer}
          durationFrom={start}
          durationTo={end}
          compareFrom={compareStart}
          compareTo={compareEnd}
          firstPathAvgPurchaseTps={groupData.first_path_avg_purchase_tps}
          hasLXRTracking={hasLXRTracking}
          trackingReady={trackingReady}
          dataSourceLength={dataSourceLength}
        />
      );
    case "product":
      return (
        <ProductPage
          orderData={segment_order_reducer}
          pathData={segment_path_reducer}
          selectedSeg={pageSegment}
          property={"revenue"}
          classes={classes}
          data={segment_product_reducer}
          compareData={segment_compare_product_reducer}
          durationFrom={start}
          durationTo={end}
          compareFrom={compareStart}
          compareTo={compareEnd}
          clustered_period={clustered_period}
        />
      );
    default:
      return null;
  }
}

function GroupPage(props) {
  const classes = GroupPageStyles();
  const theme = useTheme();
  const {
    productTourReducer,
    overview_reducer,
    segment_page_tab,
    overview_data,
    a_initial_dates,
    b_initial_dates,
    c_initial_dates,
    all_initial_dates,
    SegmentOrderData,
    SegmentPathData,
    SegmentProductData,
    PopulationData,
    segment_order_reducer,
    segment_path_reducer,
    segment_product_reducer,
    application_states,
    segment_compare_order_reducer,
    segment_compare_path_reducer,
    segment_compare_product_reducer,
    clearPushAudienceStatus,
    clearUpdateReplaceAudienceStatus,
    clearPushAudienceFBStatus,
    clearUpdateReplaceAudienceFBStatus,
    retry_re_market_action,
    businessProfile,
    fetch_custom_filter_reducer,
    fetch_custom_filters,
    set_window_height,
  } = props;
  const dispatch = useDispatch();
  const params = useParams();
  const hashString = window.location.hash;
  const pageSegment = useSelector(
    (state) => state?.application_states?.customerAnalyticsSegmentTabName
  );

  const tabValue = useSelector(
    (state) => state?.application_states?.segmentPageTab
  );

  const filterArray =
    useSelector((state) => state?.fetch_custom_filter_reducer?.filterArray) ||
    [];

  const selectedAudienceData = useSelector(
    (state) => state?.fetch_custom_filter_reducer?.selectedAudienceData
  );

  const [compareInfo, setCompareInfo] = useState({
    start: pageSegment + "Start",
    compareStart: pageSegment + "CompareStart",
    end: pageSegment + "End",
    compareEnd: pageSegment + "CompareEnd",
  });

  const applicationStart =
    useSelector((state) => state?.application_states?.[compareInfo?.start]) ||
    "";

  const [groupData, setGroupData] = useState(null);

  const [groupState, setGroupState] = useState({
    groupStart: new Date(),
    groupEnd: new Date(),
  });

  const [remarketDialogOpen, setRemarketDialogOpen] = useState(false);
  const [remarketListName, setRemarketListName] = useState(
    CustomerGroup(pageSegment)().reMarketListName
  );

  const [remarketListName2, setRemarketListName2] = useState(
    CustomerGroup(pageSegment)().name
  );

  const [localFilterState, setLocalFilterState] = useState({});

  const [datePeriodState, setDatePeriodState] = useState({
    durationFrom: new Date(),
    durationTo: new Date(),
    compareFrom: new Date(),
    compareTo: new Date(),
  });

  const clustered_period = groupData
    ? `${moment(groupData?.data_start).format("MMM YYYY")} to ${moment(
        groupData?.data_end
      ).format("MMM YYYY")}`
    : null;

  const handleTabChange = (event, newValue) => {
    segment_page_tab(newValue);
  };

  async function fetchData() {
    if (!overview_reducer.data) {
      await overview_data();
    }
    if (
      !fetch_custom_filter_reducer.isLoading &&
      fetch_custom_filter_reducer.message === ""
    ) {
      await fetch_custom_filters();
    }
    if (overview_reducer.data) {
      if (application_states[compareInfo?.start] === "") {
        await a_initial_dates(6);
        await b_initial_dates(6);
        await c_initial_dates(6);
        await all_initial_dates(6);
      }
      await PopulationData();
    }
  }

  function updateDates() {
    if (groupData) {
      setGroupState({
        groupStart: new Date(groupData.data_start),
        groupEnd: new Date(groupData.data_end),
      });
    }
  }

  const handleRemarketDialogClose = () => {
    setRemarketDialogOpen(false);
    clearPushAudienceStatus();
    clearUpdateReplaceAudienceStatus();
    clearPushAudienceFBStatus();
    clearUpdateReplaceAudienceFBStatus();
    retry_re_market_action(false, "");

    if (hashString.includes("re-authenticated")) {
      window.location.href = window.location.href.replace(
        "re-authenticated",
        ""
      );
    }
  };

  function updateCalendarDates() {
    if (application_states?.[compareInfo?.start] !== "") {
      setDatePeriodState({
        durationFrom: new Date(application_states[compareInfo?.start]),
        durationTo: new Date(application_states[compareInfo?.end]),
        compareFrom: new Date(application_states[compareInfo?.compareStart]),
        compareTo: new Date(application_states[compareInfo?.compareEnd]),
      });
    }
  }

  const handleOrderFetch = async () => {
    await SegmentOrderData(
      false,
      false,
      pageSegment,
      datePeriodState?.durationFrom,
      datePeriodState?.durationTo
    );
    if (application_states.monthCompare) {
      await SegmentOrderData(
        false,
        true,
        pageSegment,
        datePeriodState?.compareFrom,
        datePeriodState?.compareTo
      );
    }
  };

  const handlePathFetch = async () => {
    await SegmentPathData(
      false,
      false,
      pageSegment,
      false,
      datePeriodState?.durationFrom,
      datePeriodState?.durationTo
    );
    if (application_states.monthCompare) {
      await SegmentPathData(
        false,
        true,
        pageSegment,
        false,
        datePeriodState?.compareFrom,
        datePeriodState?.compareTo
      );
    }
  };

  const handleProductFetch = async () => {
    await SegmentProductData(
      false,
      false,
      pageSegment,
      false,
      datePeriodState?.durationFrom,
      datePeriodState?.durationTo
    );
    if (application_states.monthCompare) {
      await SegmentProductData(
        false,
        true,
        pageSegment,
        false,
        datePeriodState?.compareFrom,
        datePeriodState?.compareTo
      );
    }
  };

  useEffect(() => {
    if (["a", "b", "c", "all"].includes(pageSegment)) {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.hash,
      });
      window.scrollTo(0, 0);
      window.addEventListener("resize", set_window_height);
    } else {
      history.push("/axkanckanciwebd");
      reloadPage();
    }

    if (
      window.hopscotch &&
      window.hopscotch != null &&
      productTourReducer.run
    ) {
      const currentStep = window.hopscotch.getCurrStepNum();
      if (currentStep) {
        const currentTour = window.hopscotch.getCurrTour();
        window.hopscotch.startTour(currentTour, currentStep);
      }
    }

    business_profile();

    dispatch(
      selectedAudienceDetails({
        selectedAudienceData: {},
      })
    );
  }, []);

  const hasLXRTracking =
    !businessProfile.isLoading &&
    businessProfile.bu_id &&
    _.some(businessProfile.data_sources, ["data_source_type", "tracking"]);
  const dataSourceLength =
    !businessProfile.isLoading && businessProfile.bu_id
      ? businessProfile.data_sources.length
      : null;
  const trackingReady = hasLXRTracking
    ? !businessProfile.isLoading &&
      businessProfile.bu_id &&
      businessProfile.data_sources.some((val) => {
        if (val.data_source_type === "tracking") {
          const dateReady = moment(val.date_ready);
          const startOfMonth = moment().startOf("month");
          return val.date_ready
            ? dateReady >= startOfMonth
              ? false
              : true
            : false;
        }
        return null;
      })
    : null;
  useEffect(() => {
    fetchData();
    updateDates();

    if (overview_reducer?.data?.length) {
      const selectedSegment = overview_reducer?.data?.filter(
        (each) => each?.segment === pageSegment
      );

      setGroupData(selectedSegment?.[0]);

      setGroupState({
        groupStart: new Date(selectedSegment?.[0]?.data_start),
        groupEnd: new Date(selectedSegment?.[0]?.data_end),
      });
    }

    setRemarketListName(CustomerGroup(pageSegment)().reMarketListName);

    setRemarketListName2(CustomerGroup(pageSegment)().name);

    setCompareInfo({
      start: pageSegment + "Start",
      compareStart: pageSegment + "CompareStart",
      end: pageSegment + "End",
      compareEnd: pageSegment + "CompareEnd",
    });
  }, [overview_reducer?.data, pageSegment]);

  useEffect(() => {
    updateCalendarDates();
  }, [applicationStart, pageSegment]);

  useEffect(() => {
    async function fetchGroupData() {
      if (overview_reducer.data) {
        if (!moment(datePeriodState?.durationFrom).isSame(new Date(), "day")) {
          if (tabValue === "product") {
            handleProductFetch();
            handleOrderFetch();
            handlePathFetch();
          } else if (tabValue === "convPaths") {
            handlePathFetch();
            handleProductFetch();
            handleOrderFetch();
          } else {
            handleOrderFetch();
            handleProductFetch();
            handlePathFetch();
          }
        }
      }
    }

    fetchGroupData();
    //eslint-disable-next-line
  }, [datePeriodState?.compareTo, pageSegment]);

  // const clustered_period = groupData?`${moment(groupData.data_start).format("MMM YYYY")} to ${moment(groupData.data_end).format("MMM YYYY")}`:null;
  let customerFiltersList = fetch_custom_filter_reducer.filterArray;

  let local_filter;

  useEffect(() => {
    let customerFiltersList = filterArray;
    if (customerFiltersList && customerFiltersList.length > 0) {
      if (groupData?.segment === "a") {
        // local_filter = customerFiltersList.filter(f => f.filter_name.includes("All HVC's"))[0]
        local_filter = _.find(customerFiltersList, (o) => {
          return (
            !o.recencyValue[0] &&
            !o.recencyValue[1] &&
            !o.frequencyValue[0] &&
            !o.frequencyValue[1] &&
            !o.hvcScoreValue[0] &&
            !o.hvcScoreValue[1] &&
            !o.monetaryValue[0] &&
            !o.monetaryValue[1] &&
            !o.ppvValue[0] &&
            !o.ppvValue[1] &&
            !o.aovValue[0] &&
            !o.aovValue[1] &&
            o.segment_a &&
            !o.segment_b &&
            !o.segment_c &&
            !o.pred_repeat_buyer_2m &&
            !o.pred_single_repeat_buyer &&
            !o.pred_churn
          );
        });
        setLocalFilterState(local_filter);
      } else if (groupData?.segment === "b") {
        local_filter = _.find(customerFiltersList, (o) => {
          return (
            !o.recencyValue[0] &&
            !o.recencyValue[1] &&
            !o.frequencyValue[0] &&
            !o.frequencyValue[1] &&
            !o.hvcScoreValue[0] &&
            !o.hvcScoreValue[1] &&
            !o.monetaryValue[0] &&
            !o.monetaryValue[1] &&
            !o.ppvValue[0] &&
            !o.ppvValue[1] &&
            !o.aovValue[0] &&
            !o.aovValue[1] &&
            !o.segment_a &&
            o.segment_b &&
            !o.segment_c &&
            !o.pred_repeat_buyer_2m &&
            !o.pred_single_repeat_buyer &&
            !o.pred_churn
          );
        });

        setLocalFilterState(local_filter);
      } else if (groupData?.segment === "c") {
        local_filter = _.find(customerFiltersList, (o) => {
          return (
            !o.recencyValue[0] &&
            !o.recencyValue[1] &&
            !o.frequencyValue[0] &&
            !o.frequencyValue[1] &&
            !o.hvcScoreValue[0] &&
            !o.hvcScoreValue[1] &&
            !o.monetaryValue[0] &&
            !o.monetaryValue[1] &&
            !o.ppvValue[0] &&
            !o.ppvValue[1] &&
            !o.aovValue[0] &&
            !o.aovValue[1] &&
            !o.segment_a &&
            !o.segment_b &&
            o.segment_c &&
            !o.pred_repeat_buyer_2m &&
            !o.pred_single_repeat_buyer &&
            !o.pred_churn
          );
        });

        setLocalFilterState(local_filter);
      } else if (groupData?.segment === "all") {
        local_filter = _.find(customerFiltersList, (o) => {
          return (
            !o.recencyValue[0] &&
            !o.recencyValue[1] &&
            !o.frequencyValue[0] &&
            !o.frequencyValue[1] &&
            !o.hvcScoreValue[0] &&
            !o.hvcScoreValue[1] &&
            !o.monetaryValue[0] &&
            !o.monetaryValue[1] &&
            !o.ppvValue[0] &&
            !o.ppvValue[1] &&
            !o.aovValue[0] &&
            !o.aovValue[1] &&
            o.segment_a &&
            o.segment_b &&
            o.segment_c &&
            !o.pred_repeat_buyer_2m &&
            !o.pred_single_repeat_buyer &&
            !o.pred_churn
          );
        });

        setLocalFilterState(local_filter);
      }
    }
  }, [filterArray, groupData?.segment]);

  useEffect(() => {
    if (params?.selectedTab && Object.keys(localFilterState)?.length > 0) {
      dispatch(
        selectedAudienceDetails({
          selectedAudienceData: localFilterState,
        })
      );
    }

    if (hashString.includes("re-authenticated")) {
      const selectedAudienceDataOBJ = localStorage.getItem(
        "selectedAudienceDataOBJ"
      );

      dispatch(
        selectedAudienceDetails({
          selectedAudienceData: JSON.parse(selectedAudienceDataOBJ),
        })
      );
      setRemarketDialogOpen(true);
    }
  }, [params, localFilterState]);

  return (
    <>
      <Grid container>
        <Grid item md={12}>
          <div>
            {(overview_reducer.isLoading === true &&
              businessProfile.isLoading) ||
            groupData === null ? (
              <CircularProgress
                size={100}
                style={{
                  position: "relative",
                  left: "50%",
                  top: "50%",
                  color: theme.palette.secondary.light,
                }}
              />
            ) : (
              <>
                <DowntimeBanner />
                <div
                  style={{
                    position: "relative",
                    minHeight: application_states.window_height - 120,
                  }}
                >
                  <GroupOverview
                    data={groupData}
                    setRemarketDialogOpen={setRemarketDialogOpen}
                    hasLXRTracking={hasLXRTracking}
                    trackingReady={trackingReady}
                    dataSourceLength={dataSourceLength}
                  />

                  <GroupInsights
                    data={groupData}
                    tracking={businessProfile?.ecom}
                    hasLXRTracking={hasLXRTracking}
                    trackingReady={trackingReady}
                    dataSourceLength={dataSourceLength}
                  />

                  {groupState?.groupEnd.toDateString() !==
                  new Date().toDateString() ? (
                    <MonthTrendHeader
                      data={groupData}
                      groupStart={groupState?.groupStart}
                      groupEnd={groupState?.groupEnd}
                      dates={[
                        datePeriodState?.durationFrom,
                        datePeriodState?.durationTo,
                        datePeriodState?.compareFrom,
                        datePeriodState?.compareTo,
                      ]}
                      setDates={[setDatePeriodState]}
                      tabValue={tabValue}
                      handleTabChange={handleTabChange}
                      setRemarketDialogOpen={setRemarketDialogOpen}
                      hasLXRTracking={hasLXRTracking}
                      trackingReady={trackingReady}
                      dataSourceLength={dataSourceLength}
                    />
                  ) : null}

                  {getPageContent(
                    pageSegment,
                    classes,
                    tabValue,
                    segment_path_reducer,
                    segment_product_reducer,
                    segment_order_reducer,
                    segment_compare_order_reducer,
                    segment_compare_product_reducer,
                    segment_compare_path_reducer,
                    compareInfo?.start,
                    compareInfo?.end,
                    compareInfo?.compareStart,
                    compareInfo?.compareEnd,
                    clustered_period,
                    groupData,
                    hasLXRTracking,
                    trackingReady,
                    dataSourceLength
                  )}
                </div>
              </>
            )}
            {remarketDialogOpen ? (
              filterArray ? (
                <RemarketDialog
                  segment={pageSegment}
                  customFilter={localFilterState?.filter_id}
                  open={remarketDialogOpen}
                  onClose={handleRemarketDialogClose}
                  listName={remarketListName}
                  listName2={remarketListName2}
                  listSize={localFilterState?.filter_stats?.customer_count}
                />
              ) : fetch_custom_filter_reducer?.isLoading ? (
                <CircularProgress
                  size={5}
                  style={{
                    position: "relative",
                    left: "50%",
                    top: "50%",
                    color: theme.palette.primary.main,
                  }}
                />
              ) : null
            ) : null}
            <Footer />
          </div>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  overview_reducer: state.overview_reducer,
  user_segment_reducer: state.user_segment_reducer,
  application_states: state.application_states,
  segment_order_reducer: state.segment_order_reducer,
  segment_compare_order_reducer: state.segment_compare_order_reducer,
  segment_path_reducer: state.segment_path_reducer,
  segment_compare_path_reducer: state.segment_compare_path_reducer,
  segment_product_reducer: state.segment_product_reducer,
  segment_compare_product_reducer: state.segment_compare_product_reducer,
  population_data_reducer: state.population_data_reducer,
  productTourReducer: state.productTourReducer,
  userProfile: state.userProfile,
  businessProfile: state.businessProfile,
  fetch_custom_filter_reducer: state.fetch_custom_filter_reducer,
});
const mapDispatchToProps = (dispatch) => ({
  overview_data: () => dispatch(overview_data()),
  a_initial_dates: (duration) => dispatch(a_initial_dates(duration)),
  b_initial_dates: (duration) => dispatch(b_initial_dates(duration)),
  c_initial_dates: (duration) => dispatch(c_initial_dates(duration)),
  all_initial_dates: (duration) => dispatch(all_initial_dates(duration)),
  PopulationData: () => dispatch(PopulationData()),
  overview_compare: (value) => dispatch(overview_compare(value)),
  segment_page_tab: (value) => dispatch(segment_page_tab(value)),
  SegmentOrderData: (preFetch, compare, segment, start, end) =>
    dispatch(SegmentOrderData(preFetch, compare, segment, start, end)),
  SegmentPathData: (preFetch, compare, segment, top, start, end) =>
    dispatch(SegmentPathData(preFetch, compare, segment, top, start, end)),
  SegmentProductData: (preFetch, compare, segment, top, start, end) =>
    dispatch(SegmentProductData(preFetch, compare, segment, top, start, end)),
  clearPushAudienceStatus: () => dispatch(clearPushAudienceStatus()),
  clearUpdateReplaceAudienceStatus: () =>
    dispatch(clearUpdateReplaceAudienceStatus()),
  clearPushAudienceFBStatus: () => dispatch(clearPushAudienceFBStatus()),
  clearUpdateReplaceAudienceFBStatus: () =>
    dispatch(clearUpdateReplaceAudienceFBStatus()),
  retry_re_market_action: (retryReMarket, retryReMarketSource) =>
    dispatch(retry_re_market_action(retryReMarket, retryReMarketSource)),
  business_profile: () => dispatch(business_profile()),
  fetch_custom_filters: () => dispatch(fetch_custom_filters()),
  set_window_height: () => dispatch(set_window_height()),
});
export default connect(mapStateToProps, mapDispatchToProps)(GroupPage);
