import { Box, Grid, Skeleton, Tab, Tabs, Typography } from "@mui/material";
import { useTheme, withStyles } from "@mui/styles";
import { capitalCase } from "change-case";
import * as _ from "lodash";
import React from "react";
import NumericLabel from "react-pretty-numbers";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { business_profile } from "../../actions/actions";
import { border } from "../../assets/jss/commonStyles";
import GroupPageStyles from "../../assets/jss/groupPageStyles";
import { OrangeFilledButton } from "../buttons";
import Icons from "../icons";

const CustomTabs = withStyles((theme) => ({
  indicator: {
    backgroundColor: theme.palette.secondary.light,
  },
}))(Tabs);

const CustomTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    fontSize: "1rem",
    fontWeight: 400,
    minWidth: 0,
    borderRadius: "6px 6px 0px 0px",
    color: theme.palette.primary.main,
  },
  wrapper: {
    flexDirection: "row",
    justifyContent: "center",
  },
  textColorInherit: {
    color: theme.palette.primary.main,
    opacity: 1,
    // "& .Mui-selected": {
    //   fontWeight: 700,
    // },
  },
  selected: {
    fontWeight: 700,
  },
}))(Tab);

const sort_tp = (data, filter = true) => {
  let sorted_data = [];
  for (let channel in data) {
    sorted_data.push([channel, data[channel]]);
  }
  sorted_data.sort(function (a, b) {
    return b[1] - a[1];
  });
  if (filter) {
    sorted_data = _.filter(
      sorted_data,
      (o) => o[0] !== "direct" && o[0] !== "others"
    );
  }
  return sorted_data;
};

function DataBox(props) {
  const { title, value, percent, fontColor } = props;
  return (
    <Box p={1} pt={2}>
      {percent ? (
        <Typography
          variant={"h3"}
          color={fontColor ? fontColor : "primary"}
          style={{ fontWeight: 700 }}
        >
          {value} ({percent})
        </Typography>
      ) : (
        <Typography
          variant={"h3"}
          color={fontColor ? fontColor : "primary"}
          style={{ fontWeight: 700 }}
        >
          {value}
        </Typography>
      )}
      <Typography
        variant={"body1"}
        color={fontColor ? fontColor : "primary"}
        gutterBottom={true}
      >
        {title}
      </Typography>
    </Box>
  );
}
const TopPathConv = (props) => {
  const { path, orders } = props;
  const last_path = path.length - 1;
  const intermediate_nodes = path.length - 2;
  const classes = GroupPageStyles();
  const theme = useTheme();
  const paramOptions5 = {
    commafy: true,
    wholenumber: "ceil",
    shortFormat: true,
    shortFormatMinValue: 10000,
    justification: "L",
  };
  return (
    <React.Fragment>
      {path.length === 1 ? (
        <Box pt={4} pl={2} className={"flex"}>
          {Icons.singlePaidPathArrow}
          <Box pl={1}>
            <Box pb={4}>
              <Typography className={classes.timelinecontent}>
                {path[0][1] === "Google" ? Icons.googleAds : null}&nbsp;
                {path[0][1]}&nbsp;{path[0][0]}
              </Typography>
            </Box>
            <Box pt={4}>
              <Typography
                className={classes.timelinecontent}
                color={"primary"}
                style={{
                  fontSize: "1rem",
                  fontWeight: 700,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {Icons.cartIcon}&nbsp;
                <NumericLabel params={paramOptions5}>{orders}</NumericLabel>
                &nbsp;orders
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : null}
      {path.length === 2 ? (
        <Box pt={4} pl={2} className={"flex"}>
          {Icons.twoPaidPathArrow}
          <Box pl={1}>
            <Box
              pb={1}
              style={{ top: "-6px", position: "relative", minHeight: "43px" }}
            >
              <Typography className={classes.timelinecontent}>
                {path[0][1] === "Google" ? Icons.googleAds : null}&nbsp;
                {path[0][1]}&nbsp;{path[0][0]}
              </Typography>
            </Box>
            <Box pb={1} style={{ top: "-5px", position: "relative" }}>
              <Typography className={classes.timelinecontent}>
                {path[last_path][1] === "Google" ? Icons.googleAds : null}
                &nbsp;{path[last_path][1]}
                {path[last_path][0]}
              </Typography>
            </Box>
            <Box pt={1}>
              <Typography
                className={classes.timelinecontent}
                color={"primary"}
                style={{
                  fontSize: "1rem",
                  fontWeight: 700,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {Icons.cartIcon}&nbsp;
                <NumericLabel params={paramOptions5}>{orders}</NumericLabel>
                &nbsp;orders
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : null}
      {path.length > 2 ? (
        <Box pl={1.5} className={"flex"}>
          {Icons.multiPaidPathArrow}
          <Box pl={1} pt={1}>
            <Box style={{ minHeight: "43px", paddingBottom: "2px" }}>
              <Typography className={classes.timelinecontent}>
                {path[0][1] === "Google" ? Icons.googleAds : null}&nbsp;
                {path[0][1]}&nbsp;{path[0][0]}
              </Typography>
            </Box>
            <Box pb={1}>
              <Typography
                className={classes.timelinecontent}
                style={{ color: theme.palette.secondary.light }}
              >
                <NumericLabel>{intermediate_nodes}</NumericLabel>
                &nbsp;Intermediate node(s)
              </Typography>
            </Box>
            <Box pb={1} pt={1}>
              <Typography className={classes.timelinecontent}>
                {path[last_path][1] === "Google" ? Icons.googleAds : null}
                &nbsp;{path[last_path][1]}
                {path[last_path][0]}
              </Typography>
            </Box>
            <Box pt={1}>
              <Typography
                className={classes.timelinecontent}
                color={"primary"}
                style={{
                  fontSize: "1rem",
                  fontWeight: 700,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {Icons.cartIcon}&nbsp;
                <NumericLabel params={paramOptions5}>{orders}</NumericLabel>
                &nbsp;orders
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : null}
    </React.Fragment>
  );
};

function DeviceChannelNode(props) {
  const { title, data, param, total_device, borderReq, fontColor } = props;
  return (
    <Box borderBottom={borderReq ? border : null}>
      {data ? (
        <Box py={2} px={2}>
          <Grid container spacing={3}>
            <Grid item md>
              {" "}
              <Typography variant={"h5"} gutterBottom={true} color={"primary"}>
                {title}
              </Typography>
            </Grid>
            <Grid item md>
              <Typography
                variant={"h3"}
                color={fontColor ? fontColor : "primary"}
                style={{ fontWeight: 700 }}
              >
                {param === "channel"
                  ? data[0]
                    ? Math.round(data[0][1]) + "%"
                    : null
                  : data.DESKTOP > 0
                  ? Math.round((data.DESKTOP * 100) / total_device) + "%"
                  : null}
              </Typography>
              <Typography
                variant={"h5"}
                color={fontColor ? fontColor : "primary"}
              >
                {param === "channel"
                  ? data[0]
                    ? capitalCase(data[0][0]).replace(" Social", "")
                    : null
                  : data.DESKTOP > 0
                  ? "Desktop"
                  : null}
              </Typography>
            </Grid>
            <Grid item md>
              <Typography
                variant={"h3"}
                color={fontColor ? fontColor : "primary"}
                style={{ fontWeight: 700 }}
              >
                {param === "channel"
                  ? data[1]
                    ? Math.round(data[1][1]) + "%"
                    : null
                  : data.MOBILE > 0
                  ? Math.round((data.MOBILE * 100) / total_device) + "%"
                  : null}
              </Typography>
              <Typography
                variant={"h5"}
                color={fontColor ? fontColor : "primary"}
              >
                {param === "channel"
                  ? data[1]
                    ? capitalCase(data[1][0]).replace(" Social", "")
                    : null
                  : data.MOBILE > 0
                  ? "Mobile"
                  : null}
              </Typography>
            </Grid>
            <Grid item md>
              <Typography
                variant={"h3"}
                color={fontColor ? fontColor : "primary"}
                style={{ fontWeight: 700 }}
              >
                {param === "channel"
                  ? data[2]
                    ? Math.round(data[2][1]) + "%"
                    : null
                  : data.TABLET > 0
                  ? Math.round((data.TABLET * 100) / total_device) + "%"
                  : null}
              </Typography>
              <Typography
                variant={"h5"}
                color={fontColor ? fontColor : "primary"}
              >
                {param === "channel"
                  ? data[2]
                    ? capitalCase(data[2][0]).replace(" Social", "")
                    : null
                  : data.TABLET > 0
                  ? "Tablet"
                  : null}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box py={2}>
          <Typography variant={"h3"} style={{ fontWeight: 700 }}>
            NA
          </Typography>
        </Box>
      )}
    </Box>
  );
}

function GroupInsights(props) {
  const classes = GroupPageStyles();
  const {
    data,
    hasLXRTracking,
    trackingReady,
    dataSourceLength,
    businessProfile,
  } = props;

  const currency = useSelector((state) => state?.application_states?.currency);
  const tracking = useSelector((state) => state?.businessProfile?.ecom);
  const path = data.top_path;
  const theme = useTheme();
  // let total_device, total_1st_device;
  // if (data.device_all_visit) {
  //     total_device = 0;
  //     if (data.device_all_visit.DESKTOP) {
  //         total_device = total_device + data.device_all_visit.DESKTOP;
  //     }
  //     if (data.device_all_visit.MOBILE) {
  //         total_device = total_device + data.device_all_visit.MOBILE;
  //     }
  //     if (data.device_all_visit.TABLET) {
  //         total_device = total_device + data.device_all_visit.TABLET;
  //     }
  // }
  // if (data.device_1st_visit) {
  //     total_1st_device = 0;
  //     if (data.device_1st_visit.DESKTOP) {
  //         total_1st_device = total_1st_device + data.device_1st_visit.DESKTOP;
  //     }
  //     if (data.device_1st_visit.MOBILE) {
  //         total_1st_device = total_1st_device + data.device_1st_visit.MOBILE;
  //     }
  //     if (data.device_1st_visit.TABLET) {
  //         total_1st_device = total_1st_device + data.device_1st_visit.TABLET;
  //     }
  // }

  const paramOptions3 = {
    wholenumber: "ceil",
    locales: "en-US",
    // currency: true,
    shortFormat: true,
    shortFormatMinValue: 100000,
    // currencyIndicator: currency,
    commafy: true,
    justification: "L",
  };
  return (
    <React.Fragment>
      <Box px={1} marginBottom={"20px"} className={"flex"}>
        <Box className={classes.insightsStyles} width={"80%"}>
          <InsightsTabBlock
            data={data}
            currency={currency}
            tracking={tracking}
            hasLXRTracking={hasLXRTracking}
            trackingReady={trackingReady}
            dataSourceLength={dataSourceLength}
          />
        </Box>
        {!businessProfile.isLoading ? (
          hasLXRTracking && dataSourceLength === 1 ? (
            <React.Fragment>
              <Box width={"2%"}></Box>
              <Box className={classes.topPaidPath} width={"18%"}>
                <Box style={{ padding: "7px 10px 7px 10px" }}>
                  {data.path_count && (
                    <Typography color={"primary"} style={{ fontWeight: 700 }}>
                      Top path among{" "}
                      <NumericLabel params={paramOptions3}>
                        {data.path_count}
                      </NumericLabel>{" "}
                      unique paths
                    </Typography>
                  )}
                  {path ? (
                    <TopPathConv path={path} orders={data.top_path_conv} />
                  ) : (
                    <Box>
                      <Typography color={"primary"} style={{ fontWeight: 700 }}>
                        No Data to Display
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </React.Fragment>
          ) : hasLXRTracking && dataSourceLength > 1 ? (
            trackingReady ? (
              <React.Fragment>
                <Box width={"2%"}></Box>
                <Box className={classes.topPaidPath} width={"18%"}>
                  <Box style={{ padding: "7px 10px 7px 10px" }}>
                    {data.path_count && (
                      <Typography color={"primary"} style={{ fontWeight: 700 }}>
                        Top path among{" "}
                        <NumericLabel params={paramOptions3}>
                          {data.path_count}
                        </NumericLabel>{" "}
                        unique paths
                      </Typography>
                    )}
                    {path ? (
                      <TopPathConv path={path} orders={data.top_path_conv} />
                    ) : (
                      <Box
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          paddingTop: "50%",
                        }}
                      >
                        <Typography color={"primary"}>
                        No Unique Path Found.<br/> Stay tuned
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Box width={"2%"}></Box>
                <Box width={"18%"} className={classes.topPathPlaceholder}>
                  <Box
                    style={{ background: "#CBCBCB33", height: "100%" }}
                    px={2}
                  >
                    <Typography
                      style={{
                        color: theme.palette.primary.main,
                        textAlign: "center",
                        paddingTop: "30px",
                      }}
                      variant="body1"
                    >
                      Data in this section is not available
                    </Typography>
                    <Typography
                      style={{
                        color: theme.palette.primary.main,
                        textAlign: "center",
                        paddingTop: "10px",
                      }}
                      variant="body1"
                    >
                      <Link
                        to={"/settings/data-sources/tracking/data-status"}
                        style={{ textDecoration: "none" }}
                      >
                        <OrangeFilledButton style={{ borderRadius: 0 }}>
                          &nbsp;View Data Status&nbsp;
                        </OrangeFilledButton>
                      </Link>
                    </Typography>
                    <Typography
                      style={{
                        color: theme.palette.primary.main,
                        textAlign: "center",
                        paddingTop: "25px",
                      }}
                      variant="body1"
                    >
                      for more details to show.
                    </Typography>
                  </Box>
                </Box>
              </React.Fragment>
            )
          ) : (
            <React.Fragment>
              <Box width={"2%"}></Box>
              <Box width={"18%"} className={classes.topPathPlaceholder}>
                <Box style={{ background: "#CBCBCB33", height: "100%" }} px={2}>
                  <Typography
                    style={{
                      color: theme.palette.primary.main,
                      textAlign: "center",
                      paddingTop: "25px",
                    }}
                    variant="body1"
                  >
                    Data in this section is not available for the current
                    tracking method.
                  </Typography>
                  <br />
                  <Typography
                    style={{
                      color: theme.palette.primary.main,
                      textAlign: "center",
                      paddingTop: "10px",
                    }}
                    variant="body1"
                  >
                    <Link
                      to={"/settings/data-sources"}
                      style={{ textDecoration: "none" }}
                    >
                      <OrangeFilledButton style={{ borderRadius: 0 }}>
                        Add LXRTracking
                      </OrangeFilledButton>
                    </Link>
                    <br /> for more details to show.
                  </Typography>
                </Box>
              </Box>
            </React.Fragment>
          )
        ) : (
          <Box>
            <Skeleton variant="rectangle" height="192px" style={{width:'90%'}}/>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
}

const InsightsTabBlock = (props) => {
  const {
    data,
    currency,
    tracking,
    hasLXRTracking,
    trackingReady,
    dataSourceLength,
  } = props;
  const [insightTab, setInsightTab] = React.useState("orders");
  const classes = GroupPageStyles();
  let total_device, total_1st_device;
  if (data.device_all_visit) {
    total_device = 0;
    if (data.device_all_visit.DESKTOP) {
      total_device = total_device + data.device_all_visit.DESKTOP;
    }
    if (data.device_all_visit.MOBILE) {
      total_device = total_device + data.device_all_visit.MOBILE;
    }
    if (data.device_all_visit.TABLET) {
      total_device = total_device + data.device_all_visit.TABLET;
    }
  }
  if (data.device_1st_visit) {
    total_1st_device = 0;
    if (data.device_1st_visit.DESKTOP) {
      total_1st_device = total_1st_device + data.device_1st_visit.DESKTOP;
    }
    if (data.device_1st_visit.MOBILE) {
      total_1st_device = total_1st_device + data.device_1st_visit.MOBILE;
    }
    if (data.device_1st_visit.TABLET) {
      total_1st_device = total_1st_device + data.device_1st_visit.TABLET;
    }
  }
  const paramOptions5 = {
    commafy: true,
    wholenumber: "ceil",
    shortFormatMinValue: 10000,
    justification: "L",
  };
  const paramOptions2 = {
    precision: 1,
    commafy: true,
    justification: "L",
  };
  const paramOptions3 = {
    wholenumber: "ceil",
    locales: "en-US",
    currency: true,
    currencyIndicator: currency,
    commafy: true,
    justification: "L",
  };
  const paramOptions4 = {
    wholenumber: "ceil",
    justification: "L",
  };
  const theme = useTheme();
  return (
    <React.Fragment>
      <CustomTabs
        value={insightTab}
        onChange={(e, value) => {
          setInsightTab(value);
        }}
        style={{ background: "#F9FAFB" }}
      >
        <CustomTab
          id={data.segment === "a" ? "tour8thStep" : null}
          value={"orders"}
          icon={<>Orders</>}
        />
        <CustomTab value={"product"} icon={<>Product</>} />
        <CustomTab value={"price"} icon={<>Price</>} />
        <CustomTab value={"purchase path"} icon={<>Conversion Path</>} />
      </CustomTabs>
      <Box className={classes.insightsDetails}>
        {insightTab === "orders" ? (
          <Box p={2}>
            <Grid container>
              <Grid item md>
                <DataBox
                  title={"Order(s)/Customer"}
                  value={
                    <NumericLabel params={paramOptions2}>
                      {data.orders / data.customers}
                    </NumericLabel>
                  }
                />
              </Grid>
              <Grid item md>
                <DataBox
                  title={"Avg. Order Value"}
                  value={
                    <NumericLabel params={paramOptions3}>
                      {data.revenue / data.orders}
                    </NumericLabel>
                  }
                />
              </Grid>
              <Grid item md>
                <DataBox
                  title={"Avg. 1st Order Value"}
                  value={
                    data.aov_1 ? (
                      <NumericLabel params={paramOptions3}>
                        {data.aov_1}
                      </NumericLabel>
                    ) : (
                      "NA"
                    )
                  }
                />
              </Grid>
              <Grid item md>
                <DataBox
                  title={"Avg. Recency"}
                  value={
                    data.avg_recency ? (
                      <>
                        <NumericLabel params={paramOptions4}>
                          {data.avg_recency}
                        </NumericLabel>
                        <Typography component={"span"} variant={"h5"}>
                          &nbsp;Day(s)
                        </Typography>
                      </>
                    ) : (
                      "NA"
                    )
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md>
                <DataBox
                  title={"Avg. 2nd Order Value"}
                  value={
                    data.days_gap_1 !== 0 &&
                    data.days_gap_1 !== "" &&
                    data.orders / data.customers > 1.05 ? (
                      <NumericLabel params={paramOptions3}>
                        {data.aov_2}
                      </NumericLabel>
                    ) : (
                      "NA"
                    )
                  }
                />
              </Grid>
              <Grid item md>
                <DataBox
                  title={"Potential AOV"}
                  value={
                    data.avg_potential ? (
                      <NumericLabel params={paramOptions3}>
                        {data.avg_potential}
                      </NumericLabel>
                    ) : (
                      "NA"
                    )
                  }
                />
              </Grid>
              <Grid item md={4}>
                <DataBox
                  title={"Avg. Number of Items in an Order"}
                  value={
                    data.items ? (
                      <NumericLabel params={paramOptions4}>
                        {data.items / data.orders}
                      </NumericLabel>
                    ) : (
                      "NA"
                    )
                  }
                />
              </Grid>
              <Grid item md={2}></Grid>
            </Grid>
          </Box>
        ) : null}
        {insightTab === "product" ? (
          <Box p={2}>
            <Grid container>
              <Grid item md={6}>
                <DataBox
                  title={
                    data.top_product_sku ===
                    data.top_product_by_quantity_sku ? (
                      <>
                        Top Product
                        <br />
                        (by Revenue and Quantity)
                      </>
                    ) : (
                      "Top Product (by Revenue)"
                    )
                  }
                  value={data.top_product ? data.top_product : "NA"}
                  wordBreak={true}
                />
              </Grid>
              <Grid item md={3}>
                <DataBox
                  title={"Times bought"}
                  value={
                    data.top_product_quantity ? (
                      <NumericLabel params={paramOptions5}>
                        {data.top_product_quantity}
                      </NumericLabel>
                    ) : (
                      "NA"
                    )
                  }
                />
              </Grid>
              <Grid item md={3}>
                <DataBox
                  title={"Revenue generated"}
                  value={
                    data.top_product_rev ? (
                      <NumericLabel params={paramOptions3}>
                        {data.top_product_rev}
                      </NumericLabel>
                    ) : (
                      "NA"
                    )
                  }
                />
              </Grid>
            </Grid>
            {data.top_product_sku !== data.top_product_by_quantity_sku ? (
              <Grid container>
                <Grid item md={6}>
                  <DataBox
                    title={"Top Product (by Quantity)"}
                    value={
                      data.top_product_by_quantity
                        ? data.top_product_by_quantity
                        : "NA"
                    }
                  />
                </Grid>
                <Grid item md={3}>
                  <DataBox
                    title={"Times bought"}
                    value={
                      data.top_product_by_quantity_quantity ? (
                        <NumericLabel params={paramOptions5}>
                          {data.top_product_by_quantity_quantity}
                        </NumericLabel>
                      ) : (
                        "NA"
                      )
                    }
                  />
                </Grid>
                <Grid item md={3}>
                  <DataBox
                    title={"Revenue generated"}
                    value={
                      data.top_product_by_quantity_rev ? (
                        <NumericLabel params={paramOptions3}>
                          {data.top_product_by_quantity_rev}
                        </NumericLabel>
                      ) : (
                        "NA"
                      )
                    }
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid item md={3}>
                <Box height={"45px"}></Box>
              </Grid>
            )}
          </Box>
        ) : null}
        {insightTab === "price" ? (
          <Box p={2}>
            <Grid container>
              <Grid item md>
                <DataBox
                  title={"Avg. Item Price"}
                  value={
                    data.avg_price_point ? (
                      <NumericLabel params={paramOptions3}>
                        {Math.round(data.avg_price_point)}
                      </NumericLabel>
                    ) : (
                      "NA"
                    )
                  }
                />
              </Grid>
              <Grid item md>
                <DataBox
                  title={"Avg. Item Price (>1 Item in Cart)"}
                  value={
                    data.avg_price_more_items ? (
                      <NumericLabel params={paramOptions3}>
                        {Math.round(data.avg_price_more_items)}
                      </NumericLabel>
                    ) : (
                      "NA"
                    )
                  }
                />
              </Grid>
              <Grid item md></Grid>
            </Grid>
            <Grid container>
              <Grid item md>
                <DataBox
                  title={"Most Popular Item Range"}
                  value={
                    data.popular_price_end ? (
                      <>
                        <NumericLabel params={paramOptions3}>
                          {data.popular_price_start}
                        </NumericLabel>
                        &nbsp;to&nbsp;
                        <NumericLabel params={paramOptions3}>
                          {data.popular_price_end}
                        </NumericLabel>
                      </>
                    ) : (
                      "NA"
                    )
                  }
                />
              </Grid>
              <Grid item md>
                <DataBox
                  title={"Items bought in this Range"}
                  value={
                    data.popular_price_quantity ? (
                      <NumericLabel params={paramOptions5}>
                        {data.popular_price_quantity}
                      </NumericLabel>
                    ) : (
                      "NA"
                    )
                  }
                />
              </Grid>
              <Grid item md></Grid>
            </Grid>
          </Box>
        ) : null}
        {insightTab === "purchase path" ? (
          hasLXRTracking && dataSourceLength === 1 ? (
            <Box px={1}>
              <Grid container style={{ borderBottom: border }}>
                <Grid item md>
                  <DataBox
                    title={"Avg. Number of Touchpoints (1st Conversion)"}
                    value={
                      data.first_path_avg_purchase_tps ? (
                        <>
                          <NumericLabel params={paramOptions4}>
                            {data.first_path_avg_purchase_tps}
                          </NumericLabel>
                        </>
                      ) : (
                        "NA"
                      )
                    }
                  />
                </Grid>
                <Grid item md>
                  <DataBox
                    title={"Avg. Gap between 1st Visit and 1st Conversion"}
                    value={
                      data.latency ? (
                        <>
                          <NumericLabel params={paramOptions4}>
                            {data.latency / 24}
                          </NumericLabel>
                          <Typography component={"span"} variant={"h5"}>
                            &nbsp;Day(s)
                          </Typography>
                        </>
                      ) : (
                        "NA"
                      )
                    }
                  />
                </Grid>
                <Grid item md>
                  <DataBox
                    title={"Avg. Gap between 1st and 2nd Conversion"}
                    value={
                      data.days_gap_1 !== 0 &&
                      data.days_gap_1 !== "" &&
                      data.orders / data.customers > 1.05 ? (
                        <>
                          <NumericLabel params={paramOptions4}>
                            {data.days_gap_1}
                          </NumericLabel>
                          <Typography component={"span"} variant={"h5"}>
                            &nbsp;Day(s)
                          </Typography>
                        </>
                      ) : (
                        "NA"
                      )
                    }
                  />
                </Grid>
                <Grid item md>
                  <DataBox
                    title={"Avg. Gap between Conversions"}
                    value={
                      data.avg_purchase_gap ? (
                        <>
                          <NumericLabel params={paramOptions4}>
                            {data.avg_purchase_gap}
                          </NumericLabel>
                          <Typography component={"span"} variant={"h5"}>
                            &nbsp;Day(s)
                          </Typography>
                        </>
                      ) : (
                        "NA"
                      )
                    }
                  />
                </Grid>
              </Grid>
              <DeviceChannelNode
                param={"device"}
                title={"1st Visit to Conversion Path"}
                data={data.device_1st_visit}
                total_device={total_1st_device}
                borderReq={true}
              />
              <DeviceChannelNode
                param={"device"}
                title={"Visits to Conversion Path"}
                data={data.device_all_visit}
                total_device={total_device}
                borderReq={true}
              />
              <DeviceChannelNode
                param={"channel"}
                title={"1st Touchpoint share for 1st Conversion Path"}
                data={sort_tp(data.first_path_first_tp_share)}
                borderReq={true}
              />
              <DeviceChannelNode
                param={"channel"}
                title={"Touchpoint share for 1st Conversion Path"}
                data={sort_tp(data.first_path_tp_share)}
                borderReq={true}
              />
              <DeviceChannelNode
                param={"channel"}
                title={"Last Touchpoint share for 1st Conversion Path"}
                data={sort_tp(data.first_path_last_tp_share)}
                borderReq={true}
              />
            </Box>
          ) : hasLXRTracking && dataSourceLength > 1 ? (
            trackingReady ? (
              <Box px={1}>
                <Grid container style={{ borderBottom: border }}>
                  <Grid item md>
                    <DataBox
                      title={"Avg. Number of Touchpoints (1st Conversion)"}
                      value={
                        data.first_path_avg_purchase_tps ? (
                          <>
                            <NumericLabel params={paramOptions4}>
                              {data.first_path_avg_purchase_tps}
                            </NumericLabel>
                          </>
                        ) : (
                          "NA"
                        )
                      }
                    />
                  </Grid>
                  <Grid item md>
                    <DataBox
                      title={"Avg. Gap between 1st Visit and 1st Conversion"}
                      value={
                        data.latency ? (
                          <>
                            <NumericLabel params={paramOptions4}>
                              {data.latency / 24}
                            </NumericLabel>
                            <Typography component={"span"} variant={"h5"}>
                              &nbsp;Day(s)
                            </Typography>
                          </>
                        ) : (
                          "NA"
                        )
                      }
                    />
                  </Grid>
                  <Grid item md>
                    <DataBox
                      title={"Avg. Gap between 1st and 2nd Conversion"}
                      value={
                        data.days_gap_1 !== 0 &&
                        data.days_gap_1 !== "" &&
                        data.orders / data.customers > 1.05 ? (
                          <>
                            <NumericLabel params={paramOptions4}>
                              {data.days_gap_1}
                            </NumericLabel>
                            <Typography component={"span"} variant={"h5"}>
                              &nbsp;Day(s)
                            </Typography>
                          </>
                        ) : (
                          "NA"
                        )
                      }
                    />
                  </Grid>
                  <Grid item md>
                    <DataBox
                      title={"Avg. Gap between Conversions"}
                      value={
                        data.avg_purchase_gap ? (
                          <>
                            <NumericLabel params={paramOptions4}>
                              {data.avg_purchase_gap}
                            </NumericLabel>
                            <Typography component={"span"} variant={"h5"}>
                              &nbsp;Day(s)
                            </Typography>
                          </>
                        ) : (
                          "NA"
                        )
                      }
                    />
                  </Grid>
                </Grid>
                <DeviceChannelNode
                  param={"device"}
                  title={"1st Visit to Conversion Path"}
                  data={data.device_1st_visit}
                  total_device={total_1st_device}
                  borderReq={true}
                />
                <DeviceChannelNode
                  param={"device"}
                  title={"Visits to Conversion Path"}
                  data={data.device_all_visit}
                  total_device={total_device}
                  borderReq={true}
                />
                <DeviceChannelNode
                  param={"channel"}
                  title={"1st Touchpoint share for 1st Conversion Path"}
                  data={sort_tp(data.first_path_first_tp_share)}
                  borderReq={true}
                />
                <DeviceChannelNode
                  param={"channel"}
                  title={"Touchpoint share for 1st Conversion Path"}
                  data={sort_tp(data.first_path_tp_share)}
                  borderReq={true}
                />
                <DeviceChannelNode
                  param={"channel"}
                  title={"Last Touchpoint share for 1st Conversion Path"}
                  data={sort_tp(data.first_path_last_tp_share)}
                  borderReq={true}
                />
              </Box>
            ) : (
              <Box
                style={{
                  position: "relative",
                  overflow: "hidden",
                  userSelect: "none",
                }}
              >
                <Box px={1} style={{ filter: "blur(4px)" }}>
                  <Grid container style={{ borderBottom: border }}>
                    <Grid item md>
                      <DataBox
                        fontColor={"#838383"}
                        title={"Avg. Number of Touchpoints (1st Purchase)"}
                        value={
                          <>
                            <NumericLabel params={paramOptions4}>
                              {4}
                            </NumericLabel>
                          </>
                        }
                      />
                    </Grid>
                    <Grid item md>
                      <DataBox
                        fontColor={"#838383"}
                        title={"Avg. Gap between 1st Visit and 1st Purchase"}
                        value={
                          <>
                            <NumericLabel params={paramOptions4}>
                              {8}
                            </NumericLabel>
                            <Typography component={"span"} variant={"h5"}>
                              &nbsp;Day(s)
                            </Typography>
                          </>
                        }
                      />
                    </Grid>
                    <Grid item md>
                      <DataBox
                        fontColor={"#838383"}
                        title={"Avg. Gap between 1st and 2nd Purchase"}
                        value={
                          <>
                            <NumericLabel params={paramOptions4}>
                              {26}
                            </NumericLabel>
                            <Typography component={"span"} variant={"h5"}>
                              &nbsp;Day(s)
                            </Typography>
                          </>
                        }
                      />
                    </Grid>
                    <Grid item md>
                      <DataBox
                        fontColor={"#838383"}
                        title={"Avg. Gap between Purchases"}
                        value={
                          <>
                            <NumericLabel params={paramOptions4}>
                              {26}
                            </NumericLabel>
                            <Typography component={"span"} variant={"h5"}>
                              &nbsp;Day(s)
                            </Typography>
                          </>
                        }
                      />
                    </Grid>
                  </Grid>
                  <DeviceChannelNode
                    fontColor={"#838383"}
                    param={"device"}
                    title={"1st Visit to Purchase Path"}
                    data={{ DESKTOP: 3003, MOBILE: 362, TABLET: 28 }}
                    total_device={3393}
                    borderReq={true}
                  />
                </Box>
                <Box
                  style={{
                    position: "absolute",
                    top: "40%",
                    left: "40%",
                    transform: "translate(-50%, -50%)",
                    textAlign: "center",
                    color: "#ffffff",
                    fontSize: "20px",
                  }}
                >
                  <Typography
                    style={{
                      color: theme.palette.primary.main,
                      textAlign: "center",
                      paddingTop: "40px",
                    }}
                    variant="body1"
                  >
                    Data in this section is not available
                  </Typography>
                  <br />
                  <Typography
                    style={{
                      color: theme.palette.primary.main,
                      textAlign: "center",
                    }}
                    variant="body1"
                  >
                    <Link
                      to={"/settings/data-sources/tracking/data-status"}
                      style={{ textDecoration: "none" }}
                    >
                      <OrangeFilledButton style={{ borderRadius: 0 }}>
                        &nbsp;View Data Status&nbsp;
                      </OrangeFilledButton>
                    </Link>
                    &nbsp; for more details to show.
                  </Typography>
                </Box>
              </Box>
            )
          ) : (
            <Box
              style={{
                position: "relative",
                overflow: "hidden",
                userSelect: "none",
              }}
            >
              <Box px={1} style={{ filter: "blur(4px)" }}>
                <Grid container style={{ borderBottom: border }}>
                  <Grid item md>
                    <DataBox
                      fontColor={"#838383"}
                      title={"Avg. Number of Touchpoints (1st Purchase)"}
                      value={
                        <>
                          <NumericLabel params={paramOptions4}>
                            {4}
                          </NumericLabel>
                        </>
                      }
                    />
                  </Grid>
                  <Grid item md>
                    <DataBox
                      fontColor={"#838383"}
                      title={"Avg. Gap between 1st Visit and 1st Purchase"}
                      value={
                        <>
                          <NumericLabel params={paramOptions4}>
                            {8}
                          </NumericLabel>
                          <Typography component={"span"} variant={"h5"}>
                            &nbsp;Day(s)
                          </Typography>
                        </>
                      }
                    />
                  </Grid>
                  <Grid item md>
                    <DataBox
                      fontColor={"#838383"}
                      title={"Avg. Gap between 1st and 2nd Purchase"}
                      value={
                        <>
                          <NumericLabel params={paramOptions4}>
                            {26}
                          </NumericLabel>
                          <Typography component={"span"} variant={"h5"}>
                            &nbsp;Day(s)
                          </Typography>
                        </>
                      }
                    />
                  </Grid>
                  <Grid item md>
                    <DataBox
                      fontColor={"#838383"}
                      title={"Avg. Gap between Purchases"}
                      value={
                        <>
                          <NumericLabel params={paramOptions4}>
                            {26}
                          </NumericLabel>
                          <Typography component={"span"} variant={"h5"}>
                            &nbsp;Day(s)
                          </Typography>
                        </>
                      }
                    />
                  </Grid>
                </Grid>
                <DeviceChannelNode
                  fontColor={"#838383"}
                  param={"device"}
                  title={"1st Visit to Purchase Path"}
                  data={{ DESKTOP: 3003, MOBILE: 362, TABLET: 28 }}
                  total_device={3393}
                  borderReq={true}
                />
              </Box>
              <Box
                style={{
                  position: "absolute",
                  top: "40%",
                  left: "40%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center",
                  color: "#ffffff",
                  fontSize: "20px",
                }}
              >
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    textAlign: "center",
                    paddingTop: "40px",
                  }}
                  variant="body1"
                >
                  Data in this section is not available for the current tracking
                  method.
                </Typography>
                <br />
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    textAlign: "center",
                  }}
                  variant="body1"
                >
                  <Link
                    to={"/settings/data-sources"}
                    style={{ textDecoration: "none" }}
                  >
                    <OrangeFilledButton style={{ borderRadius: 0 }}>
                      Add LXRTracking
                    </OrangeFilledButton>
                  </Link>
                  &nbsp; for more details to show.
                </Typography>
              </Box>
            </Box>
          )
        ) : null}
        {/* {insightTab === "purchase path" ? (
          hasLXRTracking ? (

            trackingReady?
            <Box px={1}>
              <Grid container style={{ borderBottom: border }}>
                <Grid item md>
                  <DataBox
                    title={"Avg. Number of Touchpoints (1st Conversion)"}
                    value={
                      data.first_path_avg_purchase_tps ? (
                        <>
                          <NumericLabel params={paramOptions4}>
                            {data.first_path_avg_purchase_tps}
                          </NumericLabel>
                        </>
                      ) : (
                        "NA"
                      )
                    }
                  />
                </Grid>
                <Grid item md>
                  <DataBox
                    title={"Avg. Gap between 1st Visit and 1st Conversion"}
                    value={
                      data.latency ? (
                        <>
                          <NumericLabel params={paramOptions4}>
                            {data.latency / 24}
                          </NumericLabel>
                          <Typography component={"span"} variant={"h5"}>
                            &nbsp;Day(s)
                          </Typography>
                        </>
                      ) : (
                        "NA"
                      )
                    }
                  />
                </Grid>
                <Grid item md>
                  <DataBox
                    title={"Avg. Gap between 1st and 2nd Conversion"}
                    value={
                      data.days_gap_1 !== 0 &&
                      data.days_gap_1 !== "" &&
                      data.orders / data.customers > 1.05 ? (
                        <>
                          <NumericLabel params={paramOptions4}>
                            {data.days_gap_1}
                          </NumericLabel>
                          <Typography component={"span"} variant={"h5"}>
                            &nbsp;Day(s)
                          </Typography>
                        </>
                      ) : (
                        "NA"
                      )
                    }
                  />
                </Grid>
                <Grid item md>
                  <DataBox
                    title={"Avg. Gap between Conversions"}
                    value={
                      data.avg_purchase_gap ? (
                        <>
                          <NumericLabel params={paramOptions4}>
                            {data.avg_purchase_gap}
                          </NumericLabel>
                          <Typography component={"span"} variant={"h5"}>
                            &nbsp;Day(s)
                          </Typography>
                        </>
                      ) : (
                        "NA"
                      )
                    }
                  />
                </Grid>
              </Grid>
              <DeviceChannelNode
                param={"device"}
                title={"1st Visit to Conversion Path"}
                data={data.device_1st_visit}
                total_device={total_1st_device}
                borderReq={true}
              />
              <DeviceChannelNode
                param={"device"}
                title={"Visits to Conversion Path"}
                data={data.device_all_visit}
                total_device={total_device}
                borderReq={true}
              />
              <DeviceChannelNode
                param={"channel"}
                title={"1st Touchpoint share for 1st Conversion Path"}
                data={sort_tp(data.first_path_first_tp_share)}
                borderReq={true}
              />
              <DeviceChannelNode
                param={"channel"}
                title={"Touchpoint share for 1st Conversion Path"}
                data={sort_tp(data.first_path_tp_share)}
                borderReq={true}
              />
              <DeviceChannelNode
                param={"channel"}
                title={"Last Touchpoint share for 1st Conversion Path"}
                data={sort_tp(data.first_path_last_tp_share)}
                borderReq={true}
              />
            </Box>:
            <Box
            style={{
              position: "relative",
              overflow: "hidden",
              userSelect: "none",
            }}
          >
            <Box px={1} style={{filter:'blur(4px)'}}>
              <Grid
                container
                style={{ borderBottom: border }}
              >
                <Grid item md>
                  <DataBox
                    fontColor={"#838383"}
                    title={"Avg. Number of Touchpoints (1st Purchase)"}
                    value={
                      <>
                        <NumericLabel params={paramOptions4}>
                          {4}
                        </NumericLabel>
                      </>
                    }
                  />
                </Grid>
                <Grid item md>
                  <DataBox
                    fontColor={"#838383"}
                    title={"Avg. Gap between 1st Visit and 1st Purchase"}
                    value={
                      <>
                        <NumericLabel params={paramOptions4}>
                          {8}
                        </NumericLabel>
                        <Typography component={"span"} variant={"h5"}>
                          &nbsp;Day(s)
                        </Typography>
                      </>
                    }
                  />
                </Grid>
                <Grid item md>
                  <DataBox
                    fontColor={"#838383"}
                    title={"Avg. Gap between 1st and 2nd Purchase"}
                    value={
                      <>
                        <NumericLabel params={paramOptions4}>
                          {26}
                        </NumericLabel>
                        <Typography component={"span"} variant={"h5"}>
                          &nbsp;Day(s)
                        </Typography>
                      </>
                    }
                  />
                </Grid>
                <Grid item md>
                  <DataBox
                    fontColor={"#838383"}
                    title={"Avg. Gap between Purchases"}
                    value={
                      <>
                        <NumericLabel params={paramOptions4}>
                          {26}
                        </NumericLabel>
                        <Typography component={"span"} variant={"h5"}>
                          &nbsp;Day(s)
                        </Typography>
                      </>
                    }
                  />
                </Grid>
              </Grid>
              <DeviceChannelNode
                fontColor={"#838383"}
                param={"device"}
                title={"1st Visit to Purchase Path"}
                data={{ DESKTOP: 3003, MOBILE: 362, TABLET: 28 }}
                total_device={3393}
                borderReq={true}
              />
            </Box>
            <Box
              style={{
                position: "absolute",
                top: "40%",
                left: "40%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                color: "#ffffff",
                fontSize: "20px",
              }}
            >
              <Typography
                style={{
                  color: theme.palette.primary.main,
                  textAlign: "center",
                  paddingTop: "40px",
                }}
                variant="body1"
              >
                Data in this section is not available
              </Typography>
              <br />
              <Typography
                style={{
                  color: theme.palette.primary.main,
                  textAlign: "center",
                }}
                variant="body1"
              >
                <Link
                  to={"/settings/data-sources/tracking/data-status"}
                  style={{ textDecoration: "none" }}
                >
                  <OrangeFilledButton style={{borderRadius:0}}>&nbsp;View Data Status&nbsp;</OrangeFilledButton>
                </Link>
                &nbsp; for more details to show.
              </Typography>
            </Box>
          </Box>
          ) : (
            <Box
              style={{
                position: "relative",
                overflow: "hidden",
                userSelect: "none",
              }}
            >
              <Box px={1} style={{filter:'blur(4px)'}}>
                <Grid
                  container
                  style={{ borderBottom: border }}
                >
                  <Grid item md>
                    <DataBox
                      fontColor={"#838383"}
                      title={"Avg. Number of Touchpoints (1st Purchase)"}
                      value={
                        <>
                          <NumericLabel params={paramOptions4}>
                            {4}
                          </NumericLabel>
                        </>
                      }
                    />
                  </Grid>
                  <Grid item md>
                    <DataBox
                      fontColor={"#838383"}
                      title={"Avg. Gap between 1st Visit and 1st Purchase"}
                      value={
                        <>
                          <NumericLabel params={paramOptions4}>
                            {8}
                          </NumericLabel>
                          <Typography component={"span"} variant={"h5"}>
                            &nbsp;Day(s)
                          </Typography>
                        </>
                      }
                    />
                  </Grid>
                  <Grid item md>
                    <DataBox
                      fontColor={"#838383"}
                      title={"Avg. Gap between 1st and 2nd Purchase"}
                      value={
                        <>
                          <NumericLabel params={paramOptions4}>
                            {26}
                          </NumericLabel>
                          <Typography component={"span"} variant={"h5"}>
                            &nbsp;Day(s)
                          </Typography>
                        </>
                      }
                    />
                  </Grid>
                  <Grid item md>
                    <DataBox
                      fontColor={"#838383"}
                      title={"Avg. Gap between Purchases"}
                      value={
                        <>
                          <NumericLabel params={paramOptions4}>
                            {26}
                          </NumericLabel>
                          <Typography component={"span"} variant={"h5"}>
                            &nbsp;Day(s)
                          </Typography>
                        </>
                      }
                    />
                  </Grid>
                </Grid>
                <DeviceChannelNode
                  fontColor={"#838383"}
                  param={"device"}
                  title={"1st Visit to Purchase Path"}
                  data={{ DESKTOP: 3003, MOBILE: 362, TABLET: 28 }}
                  total_device={3393}
                  borderReq={true}
                />
              </Box>
              <Box
                style={{
                  position: "absolute",
                  top: "40%",
                  left: "40%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center",
                  color: "#ffffff",
                  fontSize: "20px",
                }}
              >
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    textAlign: "center",
                    paddingTop: "40px",
                  }}
                  variant="body1"
                >
                  Data in this section is not available for the current tracking
                  method.
                </Typography>
                <br />
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    textAlign: "center",
                  }}
                  variant="body1"
                >
                  <Link
                    to={"/settings/data-sources"}
                    style={{ textDecoration: "none" }}
                  >
                    <OrangeFilledButton style={{borderRadius:0}}>Add LXRTracking</OrangeFilledButton>
                  </Link>
                  &nbsp; for more details to show.
                </Typography>
              </Box>
            </Box>
          )
        ) : null} */}
      </Box>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  businessProfile: state.businessProfile,
});

const mapDispatchToProps = (dispatch) => ({
  business_profile: () => dispatch(business_profile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupInsights);
