import * as FileSaver from "file-saver";
import * as _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  ReportDownloaded,
  clearPushAudienceFBStatus,
  clearPushAudienceStatus,
  customer_email_download,
  emailListAudienceReset,
  get_user_data,
  get_user_hashedEmails,
  get_user_hashedEmails_filter_id,
  push_audiences_filter_param,
  save_custom_filter,
} from "../../actions/actions";
import RemarketDialogBox, { shopifyAdsValue } from "./remarketDialogBox";
import { camelCase } from "change-case";

function RemarketDialog(props) {
  const {
    filter_name,
    segment,
    open,
    userProfile,
    listName,
    listName2,
    onClose,
    listSize,
    push_audiences_reducer,
    push_audiences_filter_param,
    customFilter,
    save_custom_filter,
    save_custom_filter_reducer,
    remarket_page_states,
    list_audiences_reducer,
    customer_email_download,
    overview_reducer,
    ReportDownloaded,
    get_user_hashedEmails_filter_id,
    clearPushAudienceStatus,
    clearPushAudienceFBStatus,
    download,
    // reportButtonValueApplied,
    sidebar,
  } = props;

  const dispatch = useDispatch();

  const selectedAudienceData = useSelector(
    (state) => state?.fetch_custom_filter_reducer?.selectedAudienceData
  );

  const reportButtonValueApplied = useSelector(
    (state) => state?.application_states?.reportButtonValueApplied
  );

  const [listEdit, setListEdit] = useState(false);

  const [finalListName, setFinalListName] = useState(
    camelCase(selectedAudienceData?.filter_name) +
      "_" +
      moment().format("MMDDyyyy").toString()
  );

  const [fieldListName, setFieldListName] = useState(finalListName);

  const [finalListName2, setFinalListName2] = useState(listName2);

  const [fieldListName2, setFieldListName2] = React.useState(finalListName2);

  const [schedulerUpdateReplace, setSchedulerUpdateReplace] = useState("");
  const [updateReplacePeriod, setUpdateReplacePeriod] = useState(1);
  const [push, setPush] = useState(false);

  const handleRadioChange = (event) => {
    setSchedulerUpdateReplace(event.target.value);
  };

  // const handleFinalListName = async (name) => {
  //   await setFinalListName(name);
  // };

  const handleChangeSchedulerUpdateReplace = (event, newValue) => {
    if (newValue !== null) {
      setSchedulerUpdateReplace(newValue);
    }
  };

  useEffect(() => {}, [props]);

  useEffect(() => {
    setFinalListName(
      camelCase(selectedAudienceData?.filter_name) +
        "_" +
        moment().format("MMDDyyyy").toString()
    );
    setFinalListName2(listName2);
    setFieldListName(finalListName);
    setFieldListName2(finalListName2);
    setListEdit(false);
    //eslint-disable-next-line
  }, [open, segment]);

  const fileType = "text/csv;charset=utf-8;";
  const fileExtension = ".csv";
  const downloadFile = "lxr_" + finalListName;
  let downloadData = "Email";

  // let download = application_states.cusEmailDownload;
  const exportToCSV = async (csvData, fileName) => {
    const tableData = new Blob([csvData], { type: fileType });
    FileSaver.saveAs(tableData, fileName + fileExtension);
  };

  useEffect(() => {
    handleDownloadHashedEmail();
    //eslint-disable-next-line
  }, [list_audiences_reducer]);

  const handleDownloadHashedEmail = async () => {
    let req_data = JSON.stringify({ filter_id: customFilter });
    let reduxRequest = JSON.stringify(list_audiences_reducer.reduxRequest);

    if (
      list_audiences_reducer.hashed_emails &&
      list_audiences_reducer.isLoading === false &&
      reduxRequest === req_data &&
      download
    ) {
      downloadData =
        "Email\r\n" + list_audiences_reducer.hashed_emails.join("\r\n");

      await exportToCSV(downloadData, downloadFile);
      await customer_email_download(false);
      dispatch(emailListAudienceReset());
    }
  };

  const handleEditList = async () => {
    await setListEdit(true);
    await document.getElementById("renameList1").focus();
  };

  const DownloadHandler = async () => {
    // const functionSegment = segment === 'all' ? segmentFilter : [segment];
    await customer_email_download(true);
    await get_user_hashedEmails_filter_id(
      customFilter?.filter_id ? customFilter?.filter_id : customFilter
    );
    await handleDownloadHashedEmail();
    await ReportDownloaded(downloadFile, "Customer Hashed Email", segment);
  };

  const googlePushAudienceHandler = async (finalName) => {
    setPush(true);
    await clearPushAudienceStatus();
    let name = finalName ? finalName : finalListName;
    name.replace("lxr_", "");
    name.replace("_" + moment().format("MMDDyyyy").toString(), "");
    if (reportButtonValueApplied) {
      await save_custom_filter(
        name,
        remarket_page_states.segment_a,
        remarket_page_states.segment_b,
        remarket_page_states.segment_c,
        remarket_page_states.recency,
        remarket_page_states.frequency,
        remarket_page_states.monetary,
        remarket_page_states.aov,
        remarket_page_states.ppv,
        remarket_page_states.hvcScore,
        remarket_page_states.allRecencyValue,
        remarket_page_states.allFrequencyValue,
        remarket_page_states.allMonetaryValue,
        remarket_page_states.allAovValue,
        remarket_page_states.allPpvValue,
        remarket_page_states.allHvcScoreValue,
        remarket_page_states.pred_repeat_buyer_2m,
        remarket_page_states.pred_single_repeat_buyer,
        remarket_page_states.pred_churn,
        "auto_save"
      );
      let saved_filter = save_custom_filter_reducer.data;
      if (saved_filter) {
        await push_audiences_filter_param(
          schedulerUpdateReplace,
          updateReplacePeriod,
          "googleads",
          saved_filter.id,
          "lxr_" + name
        );
      } else {
        await push_audiences_filter_param(
          schedulerUpdateReplace,
          updateReplacePeriod,
          "googleads",
          undefined,
          "lxr_" + name
        );
      }
    } else {
      await push_audiences_filter_param(
        schedulerUpdateReplace,
        updateReplacePeriod,
        "googleads",
        customFilter,
        "lxr_" + name
      );
    }
    setPush(false);
  };

  const facebookPushAudienceHandler = async (finalName) => {
    await setPush(true);
    // await clearPushAudienceFBStatus();
    await clearPushAudienceStatus();
    let name = finalName ? finalName : finalListName;
    name.replace("lxr_", "");
    name.replace("_" + moment().format("MMDDyyyy").toString(), "");
    if (reportButtonValueApplied) {
      await save_custom_filter(
        name,
        remarket_page_states.segment_a,
        remarket_page_states.segment_b,
        remarket_page_states.segment_c,
        remarket_page_states.recency,
        remarket_page_states.frequency,
        remarket_page_states.monetary,
        remarket_page_states.aov,
        remarket_page_states.ppv,
        remarket_page_states.hvcScore,
        remarket_page_states.allRecencyValue,
        remarket_page_states.allFrequencyValue,
        remarket_page_states.allMonetaryValue,
        remarket_page_states.allAovValue,
        remarket_page_states.allPpvValue,
        remarket_page_states.allHvcScoreValue,
        remarket_page_states.pred_repeat_buyer_2m,
        remarket_page_states.pred_single_repeat_buyer,
        remarket_page_states.pred_churn,
        "auto_save"
      );
      let saved_filter = save_custom_filter_reducer.data;
      if (saved_filter) {
        await push_audiences_filter_param(
          schedulerUpdateReplace,
          updateReplacePeriod,
          "facebookads",
          saved_filter.id,
          "lxr_" + name
        );
      } else {
        await push_audiences_filter_param(
          schedulerUpdateReplace,
          updateReplacePeriod,
          "facebookads",
          undefined,
          "lxr_" + name
        );
      }
    } else {
      await push_audiences_filter_param(
        schedulerUpdateReplace,
        updateReplacePeriod,
        "facebookads",
        customFilter,
        "lxr_" + name
      );
    }
    await setPush(false);
  };

  const shopifyPushAudienceHandler = async (obj) => {
    await setPush(true);
    // await clearPushAudienceFBStatus();
    await clearPushAudienceStatus();

    // if (reportButtonValueApplied) {
    //   await save_custom_filter(
    //     name,
    //     remarket_page_states.segment_a,
    //     remarket_page_states.segment_b,
    //     remarket_page_states.segment_c,
    //     remarket_page_states.recency,
    //     remarket_page_states.frequency,
    //     remarket_page_states.monetary,
    //     remarket_page_states.aov,
    //     remarket_page_states.ppv,
    //     remarket_page_states.hvcScore,
    //     remarket_page_states.allRecencyValue,
    //     remarket_page_states.allFrequencyValue,
    //     remarket_page_states.allMonetaryValue,
    //     remarket_page_states.allAovValue,
    //     remarket_page_states.allPpvValue,
    //     remarket_page_states.allHvcScoreValue,
    //     remarket_page_states.pred_repeat_buyer_2m,
    //     remarket_page_states.pred_single_repeat_buyer,
    //     remarket_page_states.pred_churn,
    //     "auto_save"
    //   );
    //   let saved_filter = save_custom_filter_reducer.data;
    //   if (saved_filter) {
    //     await push_audiences_filter_param(
    //       schedulerUpdateReplace,
    //       updateReplacePeriod,
    //       "facebookads",
    //       saved_filter.id,
    //       "lxr_" + name
    //     );
    //   } else {
    //     await push_audiences_filter_param(
    //       schedulerUpdateReplace,
    //       updateReplacePeriod,
    //       "facebookads",
    //       undefined,
    //       "lxr_" + name
    //     );
    //   }
    // } else {
    await push_audiences_filter_param(
      obj?.schedulerUpdateReplace,
      obj?.updateReplacePeriod,
      shopifyAdsValue,
      obj?.filter_id,
      obj?.shopify_tag_name
    );
    // }
    await setPush(false);
  };

  return (
    <React.Fragment>
      <RemarketDialogBox
        open={open}
        close={onClose}
        setFieldListName={setFieldListName}
        setFieldListName2={setFieldListName2}
        fieldListName={fieldListName}
        fieldListName2={fieldListName2}
        listEdit={listEdit}
        listSize={listSize}
        listName2={listName2}
        DownloadHandler={DownloadHandler}
        filter_name={filter_name}
        list_audiences_reducer={list_audiences_reducer}
        handleEditList={handleEditList}
        setListEdit={setListEdit}
        setFinalListName={setFinalListName}
        push={push}
        userProfile={userProfile}
        schedulerUpdateReplace={schedulerUpdateReplace}
        setUpdateReplacePeriod={setUpdateReplacePeriod}
        updateReplacePeriod={updateReplacePeriod}
        handleChangeSchedulerUpdateReplace={handleChangeSchedulerUpdateReplace}
        googlePushAudienceHandler={googlePushAudienceHandler}
        push_audiences_reducer={push_audiences_reducer}
        facebookPushAudienceHandler={facebookPushAudienceHandler}
        shopifyPushAudienceHandler={shopifyPushAudienceHandler}
        sidebar={sidebar}
        handleRadioChange={handleRadioChange}
        setSchedulerUpdateReplace={setSchedulerUpdateReplace}
        // reMarketOption={reMarketOption}
        // allGroupData={allGroupData}
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  userProfile: state.userProfile,
  push_audiences_reducer: state.push_audiences_reducer,
  list_audiences_reducer: state.list_audiences_reducer,
  download: state.application_states.cusEmailDownload,
  overview_reducer: state.overview_reducer,
  save_custom_filter_reducer: state.save_custom_filter_reducer,
  filter_name: state.application_states.filter_name,
  reportButtonValueApplied: state.application_states.reportButtonValueApplied,
  remarket_page_states: state.remarket_page_states,
});
const mapDispatchToProps = (dispatch) => ({
  push_audiences_filter_param: (
    updateReplace,
    updateReplacePeriod,
    source,
    filter_id,
    audience_name
  ) =>
    dispatch(
      push_audiences_filter_param(
        updateReplace,
        updateReplacePeriod,
        source,
        filter_id,
        audience_name
      )
    ),
  get_user_hashedEmails: (customerData, segment) =>
    dispatch(get_user_hashedEmails(customerData, segment)),
  get_user_hashedEmails_filter_id: (filter_id) =>
    dispatch(get_user_hashedEmails_filter_id(filter_id)),
  get_user_data: () => dispatch(get_user_data()),
  customer_email_download: (valueDownload) =>
    dispatch(customer_email_download(valueDownload)),
  ReportDownloaded: (report_filename, report_type, segment) =>
    dispatch(ReportDownloaded(report_filename, report_type, segment)),
  clearPushAudienceStatus: () => dispatch(clearPushAudienceStatus()),
  clearPushAudienceFBStatus: () => dispatch(clearPushAudienceFBStatus()),
  save_custom_filter: (
    filterName,
    segment_a,
    segment_b,
    segment_c,
    recencyValue,
    frequencyValue,
    monetaryValue,
    aovValue,
    ppvValue,
    hvcScoreValue,
    allRecencyValue,
    allFrequencyValue,
    allMonetaryValue,
    allAovValue,
    allPpvValue,
    allHvcScoreValue,
    pred_repeat_buyer_2m,
    pred_single_repeat_buyer,
    pred_churn,
    type
  ) =>
    dispatch(
      save_custom_filter(
        filterName,
        segment_a,
        segment_b,
        segment_c,
        recencyValue,
        frequencyValue,
        monetaryValue,
        aovValue,
        ppvValue,
        hvcScoreValue,
        allRecencyValue,
        allFrequencyValue,
        allMonetaryValue,
        allAovValue,
        allPpvValue,
        allHvcScoreValue,
        pred_repeat_buyer_2m,
        pred_single_repeat_buyer,
        pred_churn,
        type
      )
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(RemarketDialog);
